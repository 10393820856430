import { ICursorPaginationResponse, IRequest, TTax, ITaxWitholding, ITaxOperation, TTaxesSummary, TTaxOperationsSummary, PostTaxWithdrawalByOperatorParams, ResponseItem, TTaxWitholdingSummary } from "@dltru/dfa-models";
import { requestDecorator } from "..";
import { HttpCode, HttpMethod } from "../../types/types";

export async function getTaxWitholdings(
  queryString: string,
): Promise<IRequest<ICursorPaginationResponse<ITaxWitholding>>> {
  return requestDecorator.call(
    this,
    `${this.paths.TaxWithholdings}${queryString}`,
    HttpMethod.GET,
    HttpCode.SUCCESS_200,
  )
}

export async function getTaxTaxes(
  queryString: string,
): Promise<IRequest<ICursorPaginationResponse<TTax>>> {
  return requestDecorator.call(
    this,
    `${this.paths.TaxTaxes}${queryString}`,
    HttpMethod.GET,
    HttpCode.SUCCESS_200,
  )
}

export async function getTaxOperations(
  queryString: string,
): Promise<IRequest<ICursorPaginationResponse<ITaxOperation>>> {
  return requestDecorator.call(
    this,
    `${this.paths.TaxOperations}${queryString}`,
    HttpMethod.GET,
    HttpCode.SUCCESS_200,
  )
}

export async function getTaxTaxesSummary(
  queryString: string,
): Promise<IRequest<ResponseItem<TTaxesSummary>>> {
  return requestDecorator.call(
    this,
    `${this.paths.TaxTaxesSummary}${queryString}`,
    HttpMethod.GET,
    HttpCode.SUCCESS_200,
  )
}

export async function getTaxOperationsSummary(
  queryString: string,
): Promise<IRequest<ResponseItem<TTaxOperationsSummary>>> {
  return requestDecorator.call(
    this,
    `${this.paths.TaxOperationsSummary}${queryString}`,
    HttpMethod.GET,
    HttpCode.SUCCESS_200,
  )
}

export async function getTaxWithholdingsSummary(
  queryString: string,
): Promise<IRequest<TTaxWitholdingSummary>> {
  return requestDecorator.call(
    this,
    `${this.paths.TaxWithholdingsSummary}${queryString}`,
    HttpMethod.GET,
    HttpCode.SUCCESS_200,
  )
}

export async function postTaxWithholdingsAnnual(
  data: { query: string },
): Promise<IRequest> {
  return requestDecorator.call(
    this,
    this.paths.TaxWithholdingsAnnual,
    HttpMethod.POST,
    HttpCode.SUCCESS_200,
    { data }
  )
}

export async function postTaxWithdrawalByOperator(
  data: PostTaxWithdrawalByOperatorParams,
): Promise<IRequest> {
  return requestDecorator.call(
    this,
    this.paths.TaxWithdrawalByOperator,
    HttpMethod.POST,
    HttpCode.SUCCESS_200,
    { data }
  )
}