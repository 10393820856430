export enum HttpMethod {
    POST = 'post',
    GET = 'get',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
}

export enum HttpCode {
    SUCCESS_200 = 200,
    SUCCESS_201 = 201,
    SUCCESS_204 = 204,
    FAIL_500 = 500,
}

export type ResponsePaging = {
    cursors?: {
        prev?: string
        next?: string
    }
}

export interface ResponseItems<T> {
    items: T[]
    error?: string
}

export interface ResponseItem<T, E = string> {
    item: T
    error?: E
}
