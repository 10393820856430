import {
    IRequest,
    SignTransactionFullType,
    UserTerminateByUserType,
    UserTerminateInitType,
} from '@dltru/dfa-models'

import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'
import { path } from '../../utils'

import { requestDecorator } from '..'

type ResetUserProfileByAdminRequest = {
    user_uuid: string
    new_email?: string
}

export async function changeBackofficeSendPhoneResetEmail(
    data: ResetUserProfileByAdminRequest,
): Promise<IRequest<void>> {
    return requestDecorator.call(
        this,
        path(this.paths.BackofficeSendPhoneResetEmail, data.user_uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_204,
    )
}

export async function changeBackofficeSendEmailResetEmail(
    data: ResetUserProfileByAdminRequest,
): Promise<IRequest<void>> {
    return requestDecorator.call(
        this,
        path(this.paths.BackofficeSendEmailResetEmail, data.user_uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_204,
        { data: { new_email: data.new_email } },
    )
}

export async function changeBackofficeSendPasswordResetEmail(
    data: ResetUserProfileByAdminRequest,
): Promise<IRequest<void>> {
    return requestDecorator.call(
        this,
        path(this.paths.BackofficeSendPasswordResetEmail, data.user_uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_204,
    )
}

export async function terminationByClient(
    data: UserTerminateByUserType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.UsersTerminationByClient,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function terminationByAdmin(
    data: UserTerminateInitType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.UsersTerminationByAdmin,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
